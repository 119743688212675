@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap);
  
/* base styles */
body {
  font-family: Raleway, sans-serif;
  margin: 0;
  font-size: 1.5em;
  text-align: center;
  background: #1b1523;
  color: #fff;
}

.App {
  display: grid;
  grid-template-columns: 1fr 3fr;
}


.cards{
  max-width: 860px;
  margin: 40px auto;
}

.menu{
  padding-left: 20px;
  padding-top: 20px;
}

.menu .options{
  margin-top: 30px;
}

button {
  background: none;
  border: 2px solid #fff;
  padding: 6px 12px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
button:hover {
  background: #c23866;
  color: #fff;
}

.card-grid{
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap:20px;
}

select {
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
  background: none;
  border: 2px solid #fff;
  padding: 6px 12px;
  border-radius: 4px;
}
select option{
  background-color:  #c23866;
}

h4{
  margin-bottom: 0px;
  padding-bottom: 5px;
}

img.hard{
  width: 75%;
}

@media only screen and (max-width: 900px) {

}

@media only screen and (max-width: 600px) {
  body{
    font-size: 1em;
  }
  .menu h1{
    font-size: 1.5em;
  }
  .App {
    display: block;
  }

  .options h4{
    display: inline;
    padding: 0px 10px 0px 10px;
    margin-bottom: 5px;
  }
  .options select{
    display: inline;
  }

  .menu .options {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .card-grid{
    grid-gap:20px;
    margin-left: 15px;
    margin-right: 15px;
  }

}

footer {
  position: absolute;
  bottom: 5px;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  width: 400px; 
  font-size: 0.7em;
}

footer a {
  text-decoration: none;
  color: wheat;
  cursor: pointer;
}


.SingleCard_card__y9bkw{
    position: relative;
}

.SingleCard_card__y9bkw img{
    width: 100%;
    display: block;
    border: 2px solid #fff;
    border-radius: 6px;
}

.SingleCard_card__y9bkw img.SingleCard_hard__3AaMk{
    width: 75%;
}

.SingleCard_card__y9bkw .SingleCard_front__2cxYs {
    -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
    transition: all ease-in 0.2s;
    position: absolute;
}

.SingleCard_flipped__dbdoT .SingleCard_front__2cxYs {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    transition-delay: 0.2s;
}

.SingleCard_card__y9bkw .SingleCard_back__1N_kL {
    transition: all ease-in 0.2s;
    transition-delay: 0.2s;
}

.SingleCard_flipped__dbdoT .SingleCard_back__1N_kL {
    -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
    transition-delay: 0s;
}
